export const validateForm = ({enteredEmail, enteredFirstname, enteredName, enteredFunctie, enteredProvincie}) => {
  let errorMsg, data;

  const fields = [
    {label: "e-mailadres", required: true, regex: /^[^\s@]{1,100}@[^\s@]{1,100}\.[^\s@]{2,64}$/, max: 254, value: enteredEmail},
    {label: "achternaam", required: true, max: 100, value: enteredName},
    {label: "voornaam", required: true, max: 100, value: enteredFirstname},
    {label: "functie", required: false, max: 100, value: enteredFunctie},
    {label: "provincie", required: false, max: 100, value: enteredProvincie},
  ]

  errorMsg = '';
  for (const element of fields) {
    if ((element.required && (!element.value || element.value.trim() === "")) || (element.regex && !element.value.toLowerCase().match(element.regex))) {
      errorMsg = `Het veld ${element.label} werd niet correct ingevuld.`;
      break;
    } else if (element.value.length > element.max) {
      errorMsg = `Het veld ${element.label} mag maximum ${element.max} karakters bevatten`
      break;
    }
  }

  if (errorMsg === "") {
    data = {
      email: enteredEmail.toLowerCase().trim(),
      voornaam: enteredFirstname.trim(),
      naam: enteredName.trim(),
    }

    if (enteredFunctie && enteredFunctie.trim() !== "") data['functie'] = enteredFunctie.trim()
    if (enteredProvincie && enteredProvincie.trim() !== "") data['provincie'] = enteredProvincie.trim()
  }

  return {errorMsg, data}
}

export const errorHandler = (response) => {
  if (response.msg[0].status === "error") {
    const fields = {
      '|voornaam|': "voornaam",
      '|naam|': "achternaam",
      '|email|': "e-mailadres",
      '|functie|': "functie",
      '|provincie|': "provincie",
    }
    let error = response.msg[0].response
    const error_fields = error.match(/\|.+?\|/g)
    const formatted_fields = error_fields.map((field) => fields[field]);
    const finalName = formatted_fields.pop();
    if (formatted_fields.length) {
      return `De velden ${formatted_fields.join(', ') + ' & ' + finalName} werden niet correct ingevuld`
    } 
    return `het ${finalName} veld werd niet correct ingevuld`
  }
  return "";
}
