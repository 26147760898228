import * as React from "react";
import { Layout } from "../components/Layout";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { PropTypes } from "prop-types";
import { errorHandler, validateForm } from "../utils/validateForm";
import { Hero } from "../components/Hero";

const Newsletter = ({ pageContext }) => {
  const { t } = useTranslation();

  pageContext.title = t("Nieuwsbrief");
  pageContext.type = "newsletter";

  const [showForm, setShowForm] = React.useState(true);
  const [message, setMessage] = React.useState("");
  const [error, setError] = React.useState("");

  const emailInputRef = React.useRef();
  const firstnameInputRef = React.useRef();
  const nameInputRef = React.useRef();
  const provincieInputRef = React.useRef();
  const functieInputRef = React.useRef();

  function formHandler(event) {
    event.preventDefault();

    const enteredEmail = emailInputRef.current.value;
    const enteredFirstname = firstnameInputRef.current.value;
    const enteredName = nameInputRef.current.value;
    const enteredFunctie = functieInputRef.current.value;
    const enteredProvincie = provincieInputRef.current.value;

    // validation
    const { errorMsg, data } = validateForm({
      enteredEmail,
      enteredFirstname,
      enteredName,
      enteredFunctie,
      enteredProvincie,
    });
    setError(t(errorMsg));
    console.log(data, new URLSearchParams(data));
    if (errorMsg === "") {
      fetch("https://pub.marketingmail.vdab.be/infopuntelerenhandler", {
        method: "POST",
        body: new URLSearchParams(data),
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      })
        .then((response) => {
          console.log(response);
          if (response.ok) return response.json();
          throw new Error(response.error);
        })
        .then((data) => {
          const error = errorHandler(data);
          if (error !== "") {
            console.log(error);
            setError(t(error));
            return;
          }
          console.log(data);
          setShowForm(false);
          setMessage(t("Bedankt voor je inschrijving op onze nieuwsbrief!"));
        })
        .catch((error) => {
          console.log(error);
          setError(t("Er liep iets fout. Gelieve opnieuw te proberen."));
        });
    }
  }

  return (
    <Layout activeDocMeta={pageContext} hideBreadcrumb>
			<Hero breadcrumbs={pageContext} title={t("Word een pro in e-leren")} image={{url: "/newsletter-visual.png", alt:"video presentatie"}} imageMobile={'-sidearea-below'} background="-light-blue-100 -newsletter">
        <p>
          Ben je opleidingsverstrekker of professional in learning &
          development? Maar is e-leren voor jou nog een onbewandeld pad?
        </p>
        <p>
          Schrijf je dan in voor onze nieuwsbrief en ontdek alles wat je moet
          weten om:
        </p>
        <ul>
          <li>zelf een online leertraject te ontwikkelen </li>
          <li>je organisatie mee te krijgen in dat verhaal </li>
          <li>je hele doelgroep te bereiken en te betrekken </li>
        </ul>
        <p>
          Ook ben je als eerste op de hoogte van interessante events en nieuwe
          video's, artikels en e-learnings op het Infopunt e-leren.
        </p>
			</Hero>

      <div className="o-wrapper">
        <div className="o-2-col">
          <div>
            {(message || error) && <div className="u-mb1">
              <h4>{message}</h4>
              <h4 className="u-text-color-red">{error}</h4>
            </div>}
            {showForm && (
              <form className="basic-form -mt-l" onSubmit={formHandler}>
                <div className="form-row">
                  <div className="form-label">
                    <label htmlFor="voornaam">{t("Voornaam")}</label>
                  </div>
                  <div className="form-field">
                    <div className="field-firstname">
                      <input
                        ref={firstnameInputRef}
                        type="text"
                        placeholder={t("Voornaam")}
                        name="voornaam"
                        id="voornaam"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-label">
                    <label htmlFor="naam">{t("Achternaam")}</label>
                  </div>
                  <div className="form-field">
                    <div className="field-lastname">
                      <input
                        ref={nameInputRef}
                        type="text"
                        placeholder={t("Achternaam")}
                        name="naam"
                        id="lastname"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-label">
                    <label htmlFor="email">{t("E-mailadres")}</label>
                  </div>
                  <div className="form-field">
                    <div className="field-email">
                      <input
                        ref={emailInputRef}
                        type="email"
                        placeholder={t("E-mailadres")}
                        name="email"
                        id="email"
                        autoComplete="off"
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-label">
                    <label htmlFor="functie">{t("Functie")}</label>
                    <span className="optional-tag c-tag -neutral -invers ml05">
                      {t("Optioneel")}
                    </span>
                  </div>
                  <div className="form-field">
                    <div className="field-function">
                      <input
                        ref={functieInputRef}
                        type="text"
                        placeholder={t("Functie")}
                        name="functie"
                        id="functie"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-label">
                    <label htmlFor="provincie">{t("Provincie")}</label>
                    <span className="optional-tag c-tag -neutral -invers ml05">
                      {t("Optioneel")}
                    </span>
                  </div>
                  <div className="form-field-icon">
                    <select
                      defaultValue={""}
                      ref={provincieInputRef}
                      name="provincie"
                      id="provincie"
                      autoComplete="off"
                      className="form-select"
                    >
                      <option value="">- {t("Geen")} -</option>
                      <option value="Antwerpen">{t("Antwerpen")}</option>
                      <option value="Brussel/Vlaams Brabant">
                        {t("Brussel/Vlaams Brabant")}
                      </option>
                      <option value="Limburg/Kempen">
                        {t("Limburg/Kempen")}
                      </option>
                      <option value="Oost-Vlaanderen">
                        {t("Oost-Vlaanderen")}
                      </option>
                      <option value="West-Vlaanderen">
                        {t("West-Vlaanderen")}
                      </option>
                    </select>
                    <span className="field-icon icon-arrow-down"></span>
                  </div>
                  <div className="form-field">
                    <div className="field-provincie"></div>
                  </div>
                </div>
                <div className="form-row form-footer">
                  <div className="form-field">
                    <div className="multi-fields">
                      <div className="multi-field">
                        <button className="c-btn -primary" type="submit">
                          {t("Inschrijven")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

Newsletter.propTypes = {
  pageContext: PropTypes.object,
};

export default Newsletter;
